import { api } from "./api";

export const settingsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getKeys: build.query<Keys[], void>({
            query: () => ({
                url: `/keys-prime-api/list`,
                method: 'GET'
            }),
        }),
        createKey: build.mutation<ApiKeyData, void>({
            query: () => ({
                url: `/keys-prime-api/create`,
                method: 'POST'
            }),
        }),
        deleteKey: build.mutation<any, string>({
            query: (publicApiKeyUid) => ({
                url: `/keys-prime-api/delete`,
                method: 'POST',
                body: {
                    publicApiKeyUid
                }
            }),
        }),
    })
})

export interface Keys {
    organizationsUids: string[]
    publicUid: string
    status: string
    usagePlan: string
    userPublicUid: string
}

interface ApiKeyData {
    apiKey: string;
    apiKeyInfo: {
      organizationsUids: string[];       
        publicUid: string;
        status: string;
        usagePlan: string;
        userPublicUid: string;
    }
}

export const {
    useLazyGetKeysQuery,
    useCreateKeyMutation,
    useDeleteKeyMutation
} = settingsApi;
  