import React, { useEffect, useMemo, useRef, useState } from "react";
import Modal from 'components/organisms/Modal';
import bigDecimal from "js-big-decimal";
import { AaveInformationMapper, BalanceModalMapper, ErrorMapper, isCollateralRemoveOrPrincipalBorrow, loanTransactionMapper, Operation, ReviewModalMapper } from "pages/DefiBorrow/DefiBorrow.constants";
import styled from "styled-components";
import LoadingStage from "pages/LoadingStage";
import Tooltip from "components/atoms/Tooltip";
import { Info } from "assets/icons";
import { ValueInput } from "components/atoms/ValueInput/ValueInput";
import { ErrorMessage } from "components/pages/custody/Custody";
import ReviewButton from "components/atoms/ReviewButton";
import FormInputBox from "components/atoms/FormInputBox";
import Link from "components/atoms/Link";
import { openInNewTab } from "shared/openInNewTab";
import { palette } from "lib/theme";
import { SelectedAsset } from "interfaces/SelectedAsset.interface";
import useAssets from "shared/useAssets";
import HealthFactorMeter from "components/molecules/HealthFactorMeter";
import { useGetLoanHealthQuery, useGetLoanInfoQuery } from "state/store/loanApi";

interface OperationModalProps {
    openModal: boolean;
    selectedAsset: SelectedAsset
    investmentAmount: string
    isPrincipal: boolean
    operation: Operation
    isDisabled: boolean
    setInvestmentAmount: (value: string) => void
    handleOperationModalClose: () => void
    handleClick: () => void
}
  
const LoanOperationModal = ({openModal, selectedAsset, investmentAmount, isPrincipal, operation, isDisabled, setInvestmentAmount, handleOperationModalClose, handleClick}: OperationModalProps) => {
    const spanDollarPrice = useRef<HTMLSpanElement>(null)
    const [minError, setMinError] = useState(false)
    const [maxError, setMaxError] = useState(false);
    const [nextHealthFactorError, setnextHealthFactorError] = useState(false);
    const [currentHealthFactorError, setCurrentHealthFactorError] = useState(false);
    const [feeError, setFeeError] = useState(false);
    const [usdEquivalent, setUsdEquivalent] = useState("$0.00");
    
    const { getPriceFormattedI } = useAssets();
    const loanHealth = useGetLoanHealthQuery({
      amount: new bigDecimal(investmentAmount).getValue(), 
      asset: selectedAsset.asset?.identifier as string, 
      network: selectedAsset.details.network, 
      transactionType: loanTransactionMapper[operation][isPrincipal ? 'principal' : 'collateral']
    })
  
    const loanInfo = useGetLoanInfoQuery({
      asset: selectedAsset?.asset?.identifier as string,
      network: selectedAsset.details.network,
      transactionType: loanTransactionMapper[operation][isPrincipal ? 'principal' : 'collateral']
    });
    
    const maximumAmount = useMemo(() => {
      if(loanInfo.data) {
        const {maxTransactionAmount} = loanInfo.data;
        const maxDueToFee = new bigDecimal(maxTransactionAmount.amount)
        return maxDueToFee;
      }
      return new bigDecimal(0);
    }, [loanInfo.data]);
  
    const minimumAmount = useMemo(() => {
      if(loanInfo.data) {
        const {minimumTransactionAmount} = loanInfo.data;
        const minimumDueToFee = new bigDecimal(minimumTransactionAmount.amount)
        return minimumDueToFee;
      }
      return new bigDecimal(0);
    }, [loanInfo.data]);

    useEffect(() => {
      setnextHealthFactorError(loanHealth?.data?.nextHealthFactor.status === "DANGER");
    }, [loanHealth.data]);

    useEffect(() => {
      if (loanInfo?.data?.availableAmountInFeeAsset) {
        setFeeError(loanInfo?.data?.availableAmountInFeeAsset.amount 
          < loanInfo?.data?.totalEstimatedFeeAmount.amount);
      }
    }, [loanInfo.data]);

    useEffect(() => {
      if (isCollateralRemoveOrPrincipalBorrow(isPrincipal ? 'principal' : 'collateral', operation)) {
        setCurrentHealthFactorError(loanHealth?.data?.currentHealthFactor.status === "DANGER");
      }
    }, [loanHealth.data, isPrincipal, operation]);
  
    useEffect(() => {
      setMinError(investmentAmount !== '0' && new bigDecimal(investmentAmount).compareTo(minimumAmount) < 0); 
      setMaxError(new bigDecimal(investmentAmount).compareTo(maximumAmount) > 0);
    }, [investmentAmount, minimumAmount, maximumAmount])


    const formatUSDValue = (value: number): string => {
      if (value === 0) return "$0.00";
      if (value < 0.01) return "< $0.01";
      if (value < 1) {
        // For values less than 1, show up to 4 decimal places
        return "$" + value.toFixed(4).replace(/0+$/, '').replace(/\.$/, '.00');
      }
      // For values >= 1, show 2 decimal places
      return "$" + value.toFixed(2);
    };

    useEffect(() => {
      if (loanInfo?.data?.rate && investmentAmount) {
        const amount = new bigDecimal(investmentAmount);
        const price = new bigDecimal(loanInfo.data?.rate.rate);
        const usdValue = amount.multiply(price);
        const numericValue = parseFloat(usdValue.getValue());
        setUsdEquivalent(formatUSDValue(numericValue));
      } else {
        setUsdEquivalent("$0.00");
      }
    }, [investmentAmount, loanInfo.data]);
  
    const changeInvestmentAmount = (value: string) => {
      const num = value.replace(/[^.0-9]/g, '');
      setInvestmentAmount(num);
    };
  
    const setMax = () => {
      if(maximumAmount.compareTo(new bigDecimal(0)) > 0) {
        setInvestmentAmount(maximumAmount.getValue());
      }
    };
  
    return (
      <Modal visible={openModal} onClose={() => handleOperationModalClose()} header={`${ReviewModalMapper[isPrincipal ? "principal" : "collateral"][operation]} ${!isPrincipal ? "Collateral" : "Asset"}`} >
        <ModalWrapper>
        <ModalHeader>
              <HeaderTitle>
                  {ReviewModalMapper[isPrincipal ? "principal" : "collateral"][operation]} {selectedAsset?.asset?.longName} ({selectedAsset?.asset?.name})
              </HeaderTitle>
        </ModalHeader>

          {(loanInfo.isLoading) && 
              <LoaderContainer>
                  <LoadingStage loadingText=' ' />
              </LoaderContainer>
          }
          {!loanInfo.isLoading && (
            <Content>
              <FormInformation>
              <InfoBox>
                {`${AaveInformationMapper[isPrincipal ? "principal" : "collateral"][operation]}`} For more information, please visit{' '}
                <Link
                    label='Aave'
                    onClick={() => openInNewTab(`https://aave.com/faq`)}
                />
              </InfoBox>
              <Space />

              <DataRow>
                  <Label>Existing custody balance</Label>
                  <Value>
                      {getPriceFormattedI(loanInfo?.data?.availableCustodyAmountInAsset?.asset, loanInfo?.data?.availableCustodyAmountInAsset?.amount)}
                      <SubValue>(${loanInfo?.data?.availableCustodyAmountValue.amount})</SubValue>
                      <Tooltip text={`Your balance available for ${ReviewModalMapper[isPrincipal ? "principal" : "collateral"][operation].toLowerCase()} may be lower than the actual account balance because of estimated network fee as well as management fee and gas fee reserve requirements`}>
                            <strong> <Info color={`${palette.white.main}`} size={12} /></strong>
                      </Tooltip>
                  </Value>
              </DataRow>

              <DataRow>
                  <Label>{`${BalanceModalMapper[isPrincipal ? "principal" : "collateral"][operation]}`}</Label>
                  <Value>
                      {getPriceFormattedI(loanInfo?.data?.availableLoanAmountInAsset?.asset, loanInfo?.data?.availableLoanAmountInAsset?.amount)}
                      <SubValue>(${loanInfo?.data?.availableLoanAmountValue.amount})</SubValue>
                  </Value>
              </DataRow>

              <DataRow>
                  <Label>Minimum amount</Label>
                    <Value>
                      {getPriceFormattedI(loanInfo?.data?.minimumTransactionAmount.asset, minimumAmount.getValue())}
                      <SubValue>(${loanInfo?.data?.minimumTransactionAmountValue?.amount})</SubValue>
                    </Value>                    
              </DataRow>

              <DataRow>
                  <Label>Maximum available</Label>
                    <Value>
                      {getPriceFormattedI(selectedAsset?.asset?.identifier, maximumAmount.getValue())}
                      <SubValue>(${loanInfo?.data?.maxTransactionAmountValue?.amount})</SubValue>
                    </Value>
              </DataRow>
                
              <Space />
                <FormInputBox>
                  <AmountContainer>{`${ReviewModalMapper[isPrincipal ? "principal" : "collateral"][operation]}`} Amount
                    <Tooltip text='Actual gas fee may be different from the estimate below. Therefore, Aave may receive a slightly different amount.'>
                        <strong>{' '}<Info color={`${palette.white.main}`} size={12} /></strong>
                    </Tooltip>
                  </AmountContainer> 
                  <ValueInputWrapper>
                    <ValueInput 
                      assetName={selectedAsset?.asset?.name}
                      changeInvestmentAmount={changeInvestmentAmount}
                      dollarPrice={"-"}
                      investmentAmount={investmentAmount}
                      renderMaxButton={true}
                      setMax={setMax}
                      spanDollarPrice={spanDollarPrice}
                      error={minError || maxError}
                    />
                    <USDValue>{usdEquivalent}</USDValue>
                   </ValueInputWrapper>
                </FormInputBox>
                <Space />

                <DataRow>
                    <Label>Estimated network fee</Label>
                    <Value>
                      {getPriceFormattedI(loanInfo?.data?.totalEstimatedFeeAmount?.asset, loanInfo?.data?.totalEstimatedFeeAmount?.amount)}
                      <SubValue>(${loanInfo?.data?.totalEstimatedFeeInConvenience?.amount})</SubValue>
                    </Value>
                </DataRow>  

                <Space />
                {loanHealth.isLoading && <LoadingStage loadingText='' />}
                {!loanHealth.isLoading && loanHealth.data && <HealthFactorMeter loanHealth={loanHealth.data} />}
  
              </FormInformation>
              <RightAlignedBox>
                <ReviewButton 
                  handleOnClick={() => handleClick()}
                  isDisabled={minError || maxError || nextHealthFactorError || currentHealthFactorError || investmentAmount === '0' || loanHealth.isFetching || isDisabled}
                  isLoading={isDisabled || loanHealth.isFetching}
                  text='Review'
                />
              </RightAlignedBox>
              {minError && <ErrorMessage>Your invest amount has to be equal or greater than the minimum invest value of {getPriceFormattedI(selectedAsset?.asset?.identifier, minimumAmount.getValue())}</ErrorMessage>}
              {maxError && <ErrorMessage>Exceeds maximum amount of {getPriceFormattedI(selectedAsset?.asset?.identifier, maximumAmount.getValue())}</ErrorMessage>}
              {nextHealthFactorError && !currentHealthFactorError && <ErrorMessage>This {ErrorMapper[isPrincipal ? "principal" : "collateral"][operation]} will increase the risk of collateral liquidation. Consider using a smaller amount to maintain health of your loan.</ErrorMessage>}
              {currentHealthFactorError && <ErrorMessage>You are unable to {ErrorMapper[isPrincipal ? "principal" : "collateral"][operation]} {isPrincipal ? "principal" : "collateral"} because health factor of your loan is too low and puts your collateral at risk of getting liquidated.</ErrorMessage>}
              {feeError && <ErrorMessage> This transaction request will fail since the SMA account balance available for fee is lower than the estimated network
                fee. Please add funds to fee asset before retrying the transaction</ErrorMessage>}
            </Content>
          )}
        </ModalWrapper>
        
      </Modal>
    )
}

const ValueInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const USDValue = styled.div`
  position: absolute;
  right: 145px;
  top: 50%;
  transform: translateY(-50%);
  color: ${palette.white.main};
  opacity: 0.7;
  font-size: 14px;
`;

const AmountContainer = styled.div`
    display: flex;
`;

const LoaderContainer = styled.div`
    padding: 24px;
    display: flex;
    justify-content: center;
`;

const FormInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RightAlignedBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const HeaderTitle = styled.h2`
    font-size: 24px;
    font-weight: 600;
    color: ${palette.white.main};
    margin: 0;
`;

const ModalWrapper = styled.div`
  padding: 16px;
`

const InfoBox = styled.div`
    background: rgba(255, 255, 255, 0.05);
    padding: 16px;
    border-radius: 8px;
    color: ${palette.white.main};
`;

const Content = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
`;

const Space = styled.div`
  margin-bottom: 6px;
`

const DataRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Label = styled.span`
    color: ${palette.white.main};
    opacity: 0.7;
`;

const Value = styled.span`
    color: ${palette.white.main};
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
`;

const SubValue = styled.span`
    color: ${palette.white.main};
    opacity: 0.7;
    font-size: 14px;
`;


export default LoanOperationModal