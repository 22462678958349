import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { StyledNav, StyledNavHeader, HideMenuButton } from 'styles/components/navigation';
import { INavigationProps } from 'interfaces/components/atoms/Atom.interface';
import NavigationItem from 'components/atoms/NavigationItem';
import Icon from 'components/atoms/Icon';
import { PagesUrl } from 'lib/constants/config.constant';
import AbraLogo from 'assets/logo/Logo.svg';
import AbraLogoSmall from 'assets/logo/Logo-small.svg';
import usePermissions from 'shared/usePermissions';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'state/slice/auth.slice';
import styled from 'styled-components';
import Modal from 'components/organisms/Modal';
import Button from 'components/atoms/Button';
import { TypesNamesEnum } from 'enums/Button.enum';

const Navigation: FC<INavigationProps> = ({ onToggleSideBar = (): void => {}, className, navRef, navigationProps }) => {
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const [helpModal, setHelpModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const { isAccountManager, canTradeCustody, canSeeSettingsPage } = usePermissions();

  const hasBoost = user?.organization?.products?.includes('Boost');
  const hasBorrow = user?.organization?.products?.includes('Borrow');
  const hasCustody = user?.organization?.products?.includes('Custody');

  const canTrade = (hasCustody && canTradeCustody) || isAccountManager;
  const canBorrow = hasCustody || isAccountManager;

  return (
    <StyledNav
      className={classNames({
        [className]: className,
      })}
      {...navigationProps}
      ref={navRef}
    >
      <NavigationContainer>
        <div>
          <StyledNavHeader bigLogo={AbraLogo} smallLogo={AbraLogoSmall}>
            <HideMenuButton onClick={onToggleSideBar}>
              <Icon name='LeftArrowBig' size={24} />
            </HideMenuButton>
          </StyledNavHeader>
          <ul
            style={{
              width: '100%',
            }}
          >
            {(hasCustody || isAccountManager) && (
              <NavigationItem
                isHidden={!hasCustody}
                text='Account'
                icon='Layers'
                link={PagesUrl.CUSTODY}
                onClick={() => navigate(PagesUrl.CUSTODY)}
              ></NavigationItem>
            )}
            {(hasCustody || isAccountManager) && (
              <NavigationItem
                isHidden={!hasCustody}
                text='Invest'
                icon='Earn'
                link={`${PagesUrl.CUSTODY}/invest`}
                onClick={() => navigate(`${PagesUrl.CUSTODY}/invest`)}
              ></NavigationItem>
            )}
            { canBorrow && (
              <NavigationItem
                isHidden={!hasCustody}
                text='Loan'
                icon='Borrow'
                link={`${PagesUrl.CUSTODY}/loan`}
                onClick={() => navigate(`${PagesUrl.CUSTODY}/loan`)}
              ></NavigationItem>
            )}
            { canTrade && (
              <NavigationItem
                isHidden={!hasCustody}
                text='Trade'
                icon='Overview'
                link={`${PagesUrl.CUSTODY}/trade`}
                onClick={() => navigate(`${PagesUrl.CUSTODY}/trade`)}
              ></NavigationItem>
            )}
            {(hasBoost || isAccountManager) && (
              <NavigationItem 
              isHidden={!hasBoost} 
              text='Boost'
              icon='Earn' 
              link={PagesUrl.BOOST} 
              onClick={() => navigate(PagesUrl.BOOST)}
              ></NavigationItem>
            )}
            {(hasBorrow || isAccountManager) && (
              <NavigationItem
                isHidden={!hasBorrow}
                text='Borrow'
                icon='Borrow'
                link={PagesUrl.BORROW}
                onClick={() => navigate(PagesUrl.BORROW)}
              ></NavigationItem>
            )}
          </ul>
        </div>
        <div>
          {canSeeSettingsPage && 
            <NavigationItem onClick={() => navigate(`${PagesUrl.CUSTODY}${PagesUrl.SETTINGS}`)} text='Settings' icon='Settings' />
          }
          <ExternalLink href='https://support.abra.com/hc/en-us/categories/25358465694619' target='_blank'>  
            <NavigationItem text='Frequently Asked Questions' icon='Info' />
          </ExternalLink>
          <NavigationItem text='Form ADV' icon='QuestionCircle' onClick={() => setHelpModal(!helpModal)} />
          <NavigationItem text='Contact Us' icon='Email' onClick={() => setContactModal(!contactModal)} />
        </div>
        <Modal visible={helpModal}>
          <Modal.Body>
            <HelpModalBox>
              <HelpModalSection>
                <HelpModalTitle>Form ADV</HelpModalTitle>
                <p>
                  Click the following link to download the{' '}
                  <ExternalLink href='https://adviserinfo.sec.gov/firm/summary/323353' target='_blank'>
                    {' '}
                    Form ADV{' '}
                  </ExternalLink>{' '}
                  from SEC's website
                </p>
              </HelpModalSection>
            </HelpModalBox>
          </Modal.Body>
          <Modal.Footer>
            <Button label='Close' buttonType={TypesNamesEnum.SECONDAY} height='30' onClick={() => setHelpModal(!helpModal)} />
          </Modal.Footer>
        </Modal>
        <Modal visible={contactModal}>
          <Modal.Body>
            <HelpModalBox>
              <HelpModalSection>
                <HelpModalTitle>Contact Email</HelpModalTitle>
                <p>
                  Please reach us out at <ExternalLink onClick={() => navigator.clipboard.writeText('support@abra.com')} href='mailto:support@abra.com'>support@abra.com</ExternalLink> for any queries or issues
                </p>
              </HelpModalSection>
            </HelpModalBox>
          </Modal.Body>
          <Modal.Footer>
            <Button label='Close' buttonType={TypesNamesEnum.SECONDAY} height='30' onClick={() => setContactModal(!contactModal)} />
          </Modal.Footer>
        </Modal>
      </NavigationContainer>
    </StyledNav>
  );
};


const HelpModalTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1vh;
  text-align: center;
`;

const HelpModalSection = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  width: calc(50% - 2vw);
`;

const HelpModalBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2vw;
  li {
    font-size: 0.6rem;
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-bottom: 2vh;
`;
export default Navigation;

const ExternalLink = styled.a`
  color: #a399f6;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.51px;
`;
