import React from "react";
import { palette } from "lib/theme";
import styled from "styled-components";
import Loading from "components/atoms/Loading";
import { SpinnerSizeEnum } from "@types/enums/Loading.enum";
import PrintIcon from "assets/icons/PrintIcon";
import usePrintMedia from "hooks/usePrintMedia";
import AbraLogo from 'assets/logo/Logo.svg';
import LogoPrint from 'assets/logo/LogoPrint.svg';

interface DocumentTitleRowProps {
  title: string
  isLoading: boolean
}

const DocumentTitleRow = ({title, isLoading}: DocumentTitleRowProps) => {
  const {isPrint, setIsPrint} = usePrintMedia()
  return (
    <TitleRow>
      { isPrint ? <img src={LogoPrint}/> : <img src={AbraLogo}/> }
      <TitlePage>
        {title}{' '}
        <PrintButton disabled={isLoading} onClick={() => setIsPrint(true)}>
          {isLoading ?
              <Loading
                showText={false}
                size={SpinnerSizeEnum.INPUT}
                showRoundSpinner
                spinnerColor={palette.white.main}
              /> :
            <PrintIcon />
          }
        </PrintButton>
      </TitlePage>
    </TitleRow>
  );
};

const TitleRow = styled.div`
  display: flex;
  width: 100%;
`

const TitlePage = styled.h2`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  line-height: 130%;
  letter-spacing: -0.5px;
  align-items: end;
  width: 100%;
  padding-left: 20px;
  @media print {
    color: black !important;
    font-weight: bold;
    td & {
      font-size: 12px
    }
    
  }
`;

const PrintButton = styled.button`
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6f2acd99;
  padding: 6px;
  border-radius: 50%;
  transition: all 1s;
  border: unset;
  &:hover {
    background-color: #6f2acd;
  }
`;

export default DocumentTitleRow;
