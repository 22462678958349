import { ButtonDefaultProps } from "interfaces/components/atoms/Atom.interface";
import styled, { css } from "styled-components";
import { ButtonType, ButtonSize } from "../../lib/constants/config.constant";

//  width: ${({size}) => (size === 'block'? '100%;' : '128px;')}
//  height: ${({size}) => (size !== 'small'? 48 : 36)}px;
export const StyledButton = styled.button<ButtonDefaultProps>`
  font-size: ${({ size }) => ((size === "small" || size === "tiny") ? "14" : "16")}px;
  top: 0%;
  bottom: 0%;
  border: none;
  border-radius: 8px;
  letter-spacing: -0.5px;
  transition: all 0.3s ease-in;
  width: ${({ width, size }) =>
    width
      ? (String(width).endsWith("%") ? width : `${width}px`)
      : ButtonSize[size] &&
        `${ButtonSize[size].width}${ButtonSize[size].measurement}`};
  height: ${({ height, size }) =>
    height
      ? (String(height).endsWith("%") ? height : `${height}px`)
      : ButtonSize[size] && `${ButtonSize[size].height}px`};

  background: ${({ buttonType, disabled }) =>
    disabled
      ? ButtonType[buttonType].disableBackgroundColor
      : ButtonType[buttonType].dafaultBackgroundColor};

  color: ${({ buttonType, disabled }) =>
    disabled
      ? ButtonType[buttonType].disableFontColor
      : ButtonType[buttonType].dafaultFontColor};

  &:hover {
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
    background: ${({ buttonType, disabled }) =>
      !disabled && ButtonType[buttonType].hoverBackgroundColor};

    color: ${({ buttonType, disabled }) =>
      !disabled && ButtonType[buttonType].hoverFontColor};
  }

  ${(props) =>
    props.isLoading
      ? css`
          pointer-events: none;
        `
      : ""}
`;
