import { palette } from "lib/theme";
import React from "react";
import styled from "styled-components"
import Tooltip from "../Tooltip";
import { Info } from "assets/icons";

interface InputAreaProps {
    size: number;
}

interface InputAmountProps {
    size: number;
}

interface InputWrapperProps {
    hasError?: boolean
}
  
const InputAmount = styled.input<InputAmountProps>`
    background-color: transparent;
    color: ${palette.white.main};
    min-width: 32ch;
    width: ${(props) => props.size+20}px;
    border: unset;
    padding: 0px 8px 0px 8px;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    -moz-appearance: textfield;
    appearance: textfield;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &:focus {
        outline: none;
    }
`;  

const InputWrapper = styled.div<InputWrapperProps>`
  border: 1px solid ${({hasError}) => hasError ? palette.red.error :  palette.white.main };
  padding: 8px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const InputArea = styled.div<InputAreaProps>`
  position: relative;
  span {
    top: 0px;
    user-select: none;
    font-size: 13.3333px;
    left: ${(props) => props.size + 13}px;
    position: absolute;
  }
`;

const MaxButton = styled.button`
  background-color: ${palette.accent.main};
  border-radius: 4px;
  color: ${palette.white.main};
  font-size: 0.7rem;
  border: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in;
  padding: 6px 18px;
  margin-left: 10px;
  &:hover {
    background-color:  ${palette.accent.light80};
  }
`;

interface ValueInputProps {
    inputAreaSize?: number
    inputAmountSize?: number
    investmentAmount: string
    renderMaxButton: boolean
    assetName: string | undefined
    dollarPrice?: string
    spanDollarPrice?: React.RefObject<HTMLSpanElement>
    error: boolean
    showTooltip?: boolean
    tooltipText?: string
    setMax: () => void
    changeInvestmentAmount: (value: string) => void
}

export const ValueInput = ({
    inputAreaSize, 
    inputAmountSize, 
    investmentAmount, 
    renderMaxButton, 
    dollarPrice, 
    assetName,
    spanDollarPrice,
    error,
    showTooltip,
    tooltipText,
    setMax, 
    changeInvestmentAmount
}: ValueInputProps) => {
    return (
        <>
            <InputWrapper hasError={error}>
                <InputArea size={inputAreaSize}>
                    <InputAmount 
                        type='number' 
                        size={inputAmountSize}  
                        value={investmentAmount} 
                        onChange={(e) => changeInvestmentAmount(e.target.value)} 
                    />
                </InputArea>
                <span ref={spanDollarPrice}>
                    {assetName} 
                    {dollarPrice && dollarPrice !== '-' ? <>({dollarPrice})</> : <></>}
                </span>
                {renderMaxButton && 
                    <MaxButton onClick={() => setMax()} >
                        MAX
                    </MaxButton>
                }
            </InputWrapper>
            {showTooltip &&
                <Tooltip text={tooltipText}>
                    <span>
                    {' '}<Info color={`${palette.white.main}`} size={12} />
                    </span>
                </Tooltip>
            }
        </>
    )
}