import { AssetAmountPair } from 'interfaces/AssetAmountPair.interface';
import { emptySplitApi as api } from './emptyApi';
export const statementsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getIncomeTotalYearToDate: build.query<null, YearMonth>({
      query: ({ month, year }) => ({ url: `/custody/statement/${year}-${month}/income/total/ytd` }),
    }),
    getIncomeTotal: build.query<null, YearMonth>({
      query: ({ month, year }) => ({ url: `/custody/statement/${year}-${month}/income/total` }),
    }),
    getRealizedTotalYearToDate: build.query<RealizedGainLossResponse[], YearMonth>({
      query: ({ month, year }) => ({ url: `/custody/statement/${year}-${month}/realized/total/ytd` }),
    }),
    getRealizedTotal: build.query<null, YearMonth>({
      query: ({ month, year }) => ({ url: `/custody/statement/${year}-${month}/realized/total` }),
    }),
    getRealized: build.query<RealizedGainLossResponse[], YearMonth>({
      query: ({ month, year }) => ({ url: `/custody/statement/${year}-${month}/realized` }),
    }),
    getRealizedWithdrawals: build.query<StatementWithdraw[], YearMonth>({
      query: ({ month, year }) => ({ url: `/custody/statement/${year}-${month}/realized/withdrawals` }),
    }),
    getTransactionsYearMonth: build.query<TransactionYearMonth[], YearMonth>({
      query: ({ month, year }) => ({ url: `/custody/transactions/month/${year}-${month}` }),
    }),
    getBalanceYearMonth: build.query<BalanceYearMonth[], YearMonth>({
      query: ({ month, year }) => ({ url: `/custody/balances/month/${year}-${month}` }),
    }),
    getBalanceYearMonthCSV: build.mutation<BalanceYearMonth[], YearMonth>({
      query: ({ month, year }) => ({ url: `/custody/balances/month/${year}-${month}/csv` }),
    }),
    getStatementTotals: build.query<StatementTotalResponse, YearMonth>({
      query: ({ month, year }) => ({ url: `/custody/statement/${year}-${month}/totals` }),
    }),
    getStatementExpenseSummary: build.query<ExpenseSummary[], YearMonth>({
      query: ({ month, year }) => ({ url: `/custody/statement/${year}-${month}/fees/totals/ytd` }),
    }),
    getStatementDefiBalances: build.query<DefiBalanceStatementRow[], YearMonth>({
      query: ({ month, year }) => `/custody/defi/balances/month/${year}-${month}`,
    }),
    getStatementDefiLoanCollateral: build.query<BalanceYearMonth[], YearMonth>({
      query: ({ month, year }) => `/custody/borrow/balance/collateral/month/${year}-${month}`,
    }),
    getStatementDefiLoanPrincipal: build.query<BalanceYearMonth[], YearMonth>({
      query: ({ month, year }) => `/custody/borrow/balance/principal/month/${year}-${month}`,
    }),
  }),
  overrideExisting: false,
});

export interface ExpenseSummary {
  amount: {
    asset: string;
    amount: string;
  };
  category: string
  costBasisAmount: {
    asset: string;
    amount: string;
  };
}
export interface TransactionYearMonth {
  dateTime: string;
  abraTxUID: string;
  networkName: string;
  actionName: string;
  strategyName: string;
  transactionCategory: string;
  amount: {
    asset: string;
    amount: string;
  };
  amountConvenienceRate: {
    baseCurrency: string;
    quoteCurrency: string;
    rate: string;
  };
  networkFee: {
    asset: string;
    amount: string;
  };
  networkFeeConvenienceRate: {
    baseCurrency: string;
    quoteCurrency: string;
    rate: string;
  };
  blockchainHash: string;
  blockchainURL: string;
}
export interface StatementWithdraw {
  network: string;
  amount: {
    asset: string;
    amount: string;
  };
  totalFees: {
    asset: string;
    amount: string;
  };
  closingDate: string;
  closingPrice: {
    baseCurrency: string;
    quoteCurrency: string;
    rate: string;
    live: string;
  };
  closingAmount: {
    asset: string;
    amount: string;
  };
  duration: string;
  realizedAmount: {
    asset: string;
    amount: string;
  };
  abraTxUID: string;
}

export interface RealizedGainLossResponse {
  network: string;
  strategyName: string;
  actionName: string;
  transactionCategory: string;
  amount: {
    asset: string;
    amount: string;
  };
  totalFees: {
    asset: string;
    amount: string;
  };
  closingDate: string;
  closingPrice: {
    baseCurrency: string;
    quoteCurrency: string;
    rate: string;
    live: string;
  };
  closingAmount: {
    asset: string;
    amount: string;
  };
  duration: string;
  realizedAmount: {
    asset: string;
    amount: string;
  };
  abraTxUID: string;
  totalCostBasis: {
    asset: string;
    amount: string;
  };
  overallCostBasis: AssetAmountPair
}
export interface BalanceYearMonth {
  network: string;
  balanceCurrency: string;
  startingBalance: AssetAmountPair;
  endingBalance: AssetAmountPair;
  valueCurrency: string;
  startingPrice: {
      baseCurrency: string;
      quoteCurrency: string;
      rate: string;
      live: boolean;
      timestamp: string;
  };
  endingPrice: {
      baseCurrency: string;
      quoteCurrency: string;
      rate: string;
      live: boolean;
      timestamp: string;
  };
  costBasis: AssetAmountPair;
  unrealizedPercentage: string;
  startingValue: AssetAmountPair;
  endingValue: AssetAmountPair;
  unrealizedAmount: AssetAmountPair;
  realizedYTD: AssetAmountPair;
  apy?: number;
  accruedAmount: AssetAmountPair
  accruedValue: AssetAmountPair
}

export interface YearMonth {
  month?: string;
  year?: string;
}

export interface DefiBalanceStatementRow {
  uid: string;
  network: string;
  strategyName: string;
  strategySource: string;
  yield: {
    value: string;
    apy: boolean;
  };
  balanceCurrency: string;
  valueCurrency: string;
  startingBalance: {
    asset: string;
    amount: string;
  };
  endingBalance: {
    asset: string;
    amount: string;
  };
  startingPrice: {
    baseCurrency: string;
    quoteCurrency: string;
    rate: number;
    live: boolean;
    timestamp: string;
  };
  endingPrice: {
    baseCurrency: string;
    quoteCurrency: string;
    rate: number;
    live: boolean;
    timestamp: string;
  };
  startingValue: {
    asset: string;
    amount: string;
  };
  endingValue: {
    asset: string;
    amount: string;
  };
  costBasis: {
    asset: string;
    amount: string;
  };
  yieldYTD: {
    asset: string;
    amount: string;
  };
  incomeYTD: {
    asset: string;
    amount: string;
  };
  realizedYTD: {
    asset: string;
    amount: string;
  };
  unrealizedAmount: {
    asset: string;
    amount: string;
  };
  unrealizedPercentage: string;
  canInvest: boolean;
  canDivest: boolean;
}

export interface StatementTotalResponse {
  incomeAmount: AssetAmountPair;
  incomeAmountYtd: AssetAmountPair;
  realizedAmount: AssetAmountPair;
  realizedAmountYtd: AssetAmountPair;
  interestAmount: AssetAmountPair;
  interestAmountYtd: AssetAmountPair;
}

export const {
  useGetIncomeTotalYearToDateQuery,
  useGetIncomeTotalQuery,
  useGetRealizedTotalQuery,
  useGetRealizedTotalYearToDateQuery,
  useGetRealizedQuery,
  useGetRealizedWithdrawalsQuery,
  useGetTransactionsYearMonthQuery,
  useGetBalanceYearMonthQuery,
  useGetStatementTotalsQuery,
  useGetBalanceYearMonthCSVMutation,
  useGetStatementDefiBalancesQuery,
  useGetStatementExpenseSummaryQuery,
  useGetStatementDefiLoanCollateralQuery,
  useGetStatementDefiLoanPrincipalQuery
} = statementsApi;
