import React from 'react';
import styled from 'styled-components';
import Image from 'components/atoms/Image';
import Loading from 'components/atoms/Loading';
import {SpinnerSizeEnum} from 'components/atoms/Loading/Loading';
import {useParams} from 'react-router-dom';
import {AssetDto} from 'state/store/api';
import useCustodyStatement from './useCustodyStatement';
import {BalanceYearMonth} from 'state/store/statementsApi';
import useAssets from 'shared/useAssets';
import {Percentage} from 'lib/utils/types';
import getUsDollar from 'shared/dollarFormat';
import moment from 'moment';
import { isNegative } from 'utils/isNegative';
import { Container, MainText, SubText, Table, TableContainer, TableRow, Td, TextGroup, Th, Title } from './sharedStyles';

const CustodyStatementTokenSummary = () => {
  const {year, month} = useParams();
  const {balance} = useCustodyStatement({year, month});
  const {getAssetByIdentifier} = useAssets();
  const date = new Date(+year!, +month! - 1);
  const lastDay = moment(date).endOf('month');
  const colspan = balance?.data ? Math.max(...(balance.data.map(m => Object.keys(m).length))) : 0;
  return (
    <Container>
      <Title>Token Summary</Title>
      {balance.isFetching && <Loading size={SpinnerSizeEnum.LARGE} showText={false} />}
      {!balance.isFetching && (
        <TableContainer>
          <Table>
            <thead>
              <TableRow>
                <Th colSpan={colspan}>Data as of {lastDay.format('MMM DD, yyyy')} 11:59 UTC</Th>
              </TableRow>
              <TableRow>
                <Th>Asset</Th>
                <Th>Network</Th>
                <Th>Market Value</Th>
                <Th>Cost Basis</Th>
                <Th title='* Any unrealized gains/losses prior to Abra deposit is not accounted for'>
                  Unrealized G/L*
                </Th>
                <Th>
                  Realized G/L*
                </Th>
                <Th>Beginning Balance</Th>
                <Th>Ending Balance</Th>
              </TableRow>
            </thead>
            <tbody>
              {balance?.data?.map((record, index) => (
                <TokenSummaryRow key={index} record={record} asset={getAssetByIdentifier(record.balanceCurrency)} />
              ))}
            </tbody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

const TokenSummaryRow = ({asset, record}: {asset?: AssetDto; record: BalanceYearMonth}) => {
  const {getPriceFormattedI} = useAssets();
  const unrealizedAmount = record.unrealizedAmount ? getUsDollar(record.unrealizedAmount?.amount) : '-';
  const unrealizedPercentage = new Percentage(record.unrealizedPercentage)?.print();
  const realizedAmount = record.realizedYTD ? getUsDollar(record.realizedYTD?.amount) : '-';
  return (
    <TableRow>
      <Td>
        <TokenImageBox>
          {asset ? (
            <>
              <ImageBox>
                <Image width='100%' height='100%' src={asset?.icon} />
              </ImageBox>
              <TextGroup>
                <MainText>{asset?.name}</MainText>
              </TextGroup>
            </>
          ) : (
            <TextGroup>
              <MainText>{record?.endingBalance?.asset}</MainText>
            </TextGroup>
          )}
        </TokenImageBox>
      </Td>
      <Td>
        <TextGroup>
          <MainText>{record.network}</MainText>
        </TextGroup>
      </Td>
      <Td>
        <MainText>{record?.endingPrice?.rate ? getUsDollar(record.endingPrice.rate) : '--'}</MainText>
      </Td>
      <Td>
        <TextGroup>
          {!record.costBasis ? (
            <>--</>
          ) : (
            <MainText negative={isNegative(record.costBasis.amount)}>
              {getPriceFormattedI(record.costBasis.asset, record.costBasis.amount)}
            </MainText>
          )}
        </TextGroup>
      </Td>
      <Td>
        {!record?.unrealizedAmount ? (
          <>--</>
        ) : (
          <MainText negative={isNegative(record.unrealizedAmount.amount)}>
            {unrealizedAmount}
            {unrealizedPercentage && (
              <SubText negative={isNegative(record.unrealizedAmount.amount)}>
                ({unrealizedPercentage})
              </SubText>
            )}
          </MainText>
        )}
      </Td>
      <Td>
        {!record?.realizedYTD ? (
          <>--</>
        ) : (
          <MainText negative={isNegative(record.realizedYTD.amount)}>
            {realizedAmount}
          </MainText>
        )}
      </Td>
      <Td>
        <TextGroup>
          {!record?.startingValue ? (
            <>--</>
          ) : (
            <>
              <MainText negative={isNegative(record.startingValue.amount)}>
                {getUsDollar(record.startingValue.amount)}
              </MainText>
              {record?.startingBalance && (
                <SubText negative={isNegative(record.startingBalance.amount)}>
                  {getPriceFormattedI(record.startingBalance.asset, record.startingBalance.amount)}
                </SubText>
              )}
            </>
          )}
        </TextGroup>
      </Td>
      <Td>
        <TextGroup>
          {!record?.endingValue ? (
            <>--</>
          ) : (
            <>
              <MainText negative={isNegative(record.endingValue.amount)}>
                {getUsDollar(record.endingValue.amount)}
              </MainText>
              {record?.endingBalance && (
                <SubText negative={isNegative(record.endingBalance.amount)}>
                  {getPriceFormattedI(record.endingBalance.asset, record.endingBalance.amount)}
                </SubText>
              )}
            </>
          )}
        </TextGroup>
      </Td>
    </TableRow>
  );
};

const ImageBox = styled.div`
  max-width: calc(min(4vw, 25px));
  min-width: calc(min(4vw, 25px));
`; 

const TokenImageBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6vw;
`;

export default CustodyStatementTokenSummary;