import React from "react";
import Modal from 'components/organisms/Modal';
import bigDecimal from "js-big-decimal";
import StrongText from "components/atoms/StrongText";
import { loanTransactionMapper, Operation, ReviewModalMapper } from "pages/DefiBorrow/DefiBorrow.constants";
import styled from "styled-components";
import LoadingStage from "pages/LoadingStage";
import ReviewButton from "components/atoms/ReviewButton";
import { SelectedAsset } from "interfaces/SelectedAsset.interface";
import useAssets from "shared/useAssets";
import HealthFactorMeter from "components/molecules/HealthFactorMeter";
import { useGetLoanHealthQuery, useGetLoanInfoQuery } from "state/store/loanApi";

interface LoanReviewModalProps {
  openReviewModal: boolean;
  selectedAsset: SelectedAsset
  operation: Operation
  isPrincipal: boolean
  investmentAmount: string
  isDisabled: boolean
  handleReviewModalClose: () => void
  handleClick: () => void
  transactionId: string | null
}

const LoanReviewModal = ({openReviewModal, selectedAsset, operation, isPrincipal, investmentAmount, transactionId, isDisabled, handleReviewModalClose, handleClick}: LoanReviewModalProps) => {
  const { getPriceFormattedI } = useAssets();

  const borrowInfo = useGetLoanInfoQuery({
    asset: selectedAsset?.asset?.identifier as string,
    network: selectedAsset.details.network,
    transactionType: loanTransactionMapper[operation][isPrincipal ? 'principal' : 'collateral']
  });

  const renderEstimatedFee = () => {
    if(!borrowInfo) return null
    return (<p>
      Estimated network fee is{' '}
      <StrongText>
        {getPriceFormattedI(borrowInfo.data?.totalEstimatedFeeAmount?.asset, borrowInfo.data?.totalEstimatedFeeAmount?.amount)}
      </StrongText>
      {' '}(${borrowInfo.data?.totalEstimatedFeeInConvenience.amount})
    </p>)
  }

  const borrowHealth = useGetLoanHealthQuery({
    amount: new bigDecimal(investmentAmount).getValue(), 
    asset: selectedAsset.asset?.identifier as string, 
    network: selectedAsset.details.network, 
    transactionType: loanTransactionMapper[operation][isPrincipal ? 'principal' : 'collateral']
  })

  return (
    <Modal visible={openReviewModal} onClose={() => handleReviewModalClose()} header='Confirmation Page'>
      <ModalWrapper>
        <ModalContent>
          {operation && 
            <p>
              This operation will {ReviewModalMapper[isPrincipal ? "principal" : "collateral"][operation].toLocaleLowerCase()}{' '}
              <StrongText>
                {investmentAmount} {selectedAsset?.asset?.name}
              </StrongText>
            </p>
          }
          <p>
            {renderEstimatedFee()}
          </p>
          {borrowHealth.isLoading && <LoadingStage loadingText='' />}
          {!borrowHealth.isLoading && borrowHealth.data && <HealthFactorMeter loanHealth={borrowHealth.data} />}
        </ModalContent>
      </ModalWrapper>
      <ModalWrapper>
        <RightAlignedBox>
          <ReviewButton
            handleOnClick={() => handleClick()}
            isLoading={isDisabled}
            isDisabled={!transactionId || isDisabled}
            text='Submit'
          />
        </RightAlignedBox>
      </ModalWrapper>
  </Modal>
  )
}

const ModalWrapper = styled.div`
  padding: 16px;
`

const RightAlignedBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ModalContent = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  font-size: 0.9rem;
`;

export default LoanReviewModal