import { useState, useEffect } from 'react';

const usePrintMedia = () => {
  const [isPrint, setIsPrint] = useState(false);

  // Effect to trigger print when isPrint changes to true
  useEffect(() => {
    if (isPrint) {
      window.print();
    }
  }, [isPrint]);

  // Effect to handle media query changes
  useEffect(() => {
    const mediaQueryList = window.matchMedia('print');

    const handleMediaChange = (event) => {
      setIsPrint(event.matches);
    };

    mediaQueryList.addEventListener('change', handleMediaChange);

    // Initial check
    setIsPrint(mediaQueryList.matches);

    return () => {
      mediaQueryList.removeEventListener('change', handleMediaChange);
    };
  }, []);

  return {isPrint, setIsPrint};
};

export default usePrintMedia;
