export enum TypesNamesEnum {
  PRIMARY = "primary",
  SECONDAY = "secondary",
  ACCENT = "accent",
}

export enum SizeNamesEnum {
  NORMAL = "normal",
  DEFAULT = "default",
  SMALL = "small",
  MEDIUM = "medium",
  MEDIUM_MODAL = "medium_modal",
  BLOCK = "block",
  BLOCK_LARGE = "block_large",
  SMALL_LARGE = "small_large",
  DEFAULT_LARGE = "default_large",
  EXTRA_LARGE = "extra_large",
  EXTRA_LARGE_PLUS = "extra_large_plus",
  TINY = "tiny"
}

export enum StatusNamesEnum {
  DEFAULT = "default",
  DISABLED = "disabled",
  HOVER = "hover",
}
