import React from 'react';
import { useParams } from 'react-router-dom';
import { RealizedGainLossResponse } from 'state/store/statementsApi';
import useAssets from 'shared/useAssets';
import InkindAssetRenderer from 'components/atoms/asset/InkindAssetRenderer';
import useCustodyStatement from './useCustodyStatement';
import { Container, Table, TableContainer, TableRow, Th, Td, Title } from './sharedStyles';

const CustodyStatementGainLossDetails = () => {
  const { month, year } = useParams();
  const { realizedGainLoss } = useCustodyStatement({ month, year });
  const gainLoss = realizedGainLoss?.data ?? [];

  return (
    <Container>
      <Title>Gain / Loss Details</Title>
      <TableContainer>
        <Table>
          <thead>
            <TableRow>
              <Th>Date</Th>
              <Th>Strategy</Th>
              <Th>Action</Th>
              <Th>Type</Th>
              <Th>Closing Amount</Th>
              <Th>Closing Quantity</Th>
              <Th>Closing Price</Th>
              <Th>Cost Basis</Th>
              <Th>Transaction Id</Th>
              <Th>Duration</Th>
              <Th>Realized G/L***</Th>
            </TableRow>
          </thead>
          <tbody>
            {gainLoss.map((record, index) => (
              <CustodySummaryRow key={index} record={record} />
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

const CustodySummaryRow = ({ record }: { record: RealizedGainLossResponse }) => {
  const { getPriceFormattedI } = useAssets();
  const strategyName = record.strategyName ?? '-';
  const actionName = record.actionName ?? '-';
  const transactionCategory = record.transactionCategory ?? '-';

  return (
    <TableRow>
      <Td>{record.closingDate}</Td>
      <Td>{strategyName}</Td>
      <Td>{actionName}</Td>
      <Td>{transactionCategory}</Td>
      <Td>
        {getPriceFormattedI(record.closingAmount.asset, record.closingAmount.amount)}
      </Td>
      <Td>
        <InkindAssetRenderer balance={record.amount} />
      </Td>
      <Td>
        <InkindAssetRenderer 
          balance={{
            amount: record.closingPrice.rate, 
            asset: record.closingPrice.quoteCurrency
          }} 
        />
      </Td>
      <Td>
        <InkindAssetRenderer balance={record.overallCostBasis} />
      </Td>
      <Td>{record.abraTxUID}</Td>
      <Td>{record.duration}</Td>
      <Td>
        <InkindAssetRenderer balance={record.realizedAmount} />
      </Td>
    </TableRow>
  );
};


export default CustodyStatementGainLossDetails;