import React, { FC, useState } from "react";
import { getText } from "shared/locale-helper";
import { palette } from "lib/theme";
import { Text } from "components/atoms/Typography";
import AnimatedWidgetStep from "components/atoms/Animated/AnimatedWidgetStep";
import Icon from "components/atoms/Icon";
import LtbInformationBody from "components/molecules/LtbInformationBody/LtbInformationBody";
import Sidebar from "components/molecules/Sidebar";
import styled from "styled-components";
import Tooltip from "components/atoms/Tooltip";
import { OrderDto } from "state/store/api";
import { getInfoNotification } from "./alerts/NotificationLTV";
import { formatNumberDisplay } from "shared";

export type ChartMeasures = {
  safe: number;
  warning: number;
  danger: number;
  initialLtv?: number;
};
interface ILoanToValueRatio {
  order?: OrderDto;
  chartMeasures?: ChartMeasures;
  currentLtv?: number;
  incomingNotificationType?: string
}

const LoanToValueRatio: FC<ILoanToValueRatio> = ({
  order,
  chartMeasures = {
    safe: 0.65,
    warning: 0.65,
    danger: 0.65,
  },
  currentLtv = 8,
  incomingNotificationType
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  const getCurrentLtvColor = () => {
    const notificationType = incomingNotificationType || (currentLtv < chartMeasures.safe ? "safe" : currentLtv < chartMeasures.danger ? "warning" : "danger") 
    if (notificationType === "warning") return palette.orange.main;
    if (notificationType === "danger") return palette.red.main;

    return palette.green.main;
  };

  const getTooltipText = () => {
    return (
      <div style={{ textAlign: "left" }}>
        <Text size="small" letterSpacing="-0.5">
          {getText("ltv_refer")}
          <br />
          <br />
          {getText("ltv_example")}
          <Text
            size="small"
            letterSpacing="-0.5"
            onClick={() => setIsSidebarOpen(true)}
            color={palette.purple.light}
          >
            {getText("learn_more")}
          </Text>
        </Text>
      </div>
    );
  };

  return (
    <>
      <TitleRow>
        <Text
          size="tiny"
          bold
          letterSpacing="0.5px"
          color={palette.darkBackgroundContrast.light70}
        >
          {String("LTV (Loan-to-value)").toUpperCase()}
        </Text>
        <Tooltip
          displayAsText
          autoDismiss={false}
          maxWidth={450}
          placement="bottom"
          text={getTooltipText()}
        >
          <Icon name="QuestionCircleBig" size="12" />
        </Tooltip>
      </TitleRow>
      <ChartBox>
        <StyledLegendContainer offsetX={currentLtv} offsetY={-34}>
          <span style={{ color: getCurrentLtvColor() }}>
            {getText("current")} ({`${formatNumberDisplay(currentLtv * 100, 'decimal')}%`})
          </span>
          <StyledPointer>
            <Icon
              name="LTVIndicatorDown"
              color={getCurrentLtvColor()}
              size={12}
            />
          </StyledPointer>
        </StyledLegendContainer>
        <StyledLTVContainer>
          <StyledLTVPartialFill
            background={palette.green.main as string}
            width={chartMeasures.safe}
            initial
          />
          <StyledLTVPartialFill
            background={palette.orange.main as string}
            width={chartMeasures.warning}
          />
          <StyledLTVPartialFill
            background={palette.red.main as string}
            width={chartMeasures.danger}
          />
        </StyledLTVContainer>
        <StyledLegendContainer offsetX={chartMeasures.safe} offsetY={13}>
          <StyledPointer>
            <Icon name="LTVIndicatorUp" size={12} />
          </StyledPointer>
          <Text color={palette.secondary.secondaryFont} size="tiny">
            {getText("ltv_orange_desc")}
          </Text>
        </StyledLegendContainer>

        <StyledLegendContainer offsetX={chartMeasures.safe + chartMeasures.warning} offsetY={13}>
          <StyledPointer>
            <Icon name="LTVIndicatorUp" size={12} />
          </StyledPointer>
          <Text color={palette.secondary.secondaryFont} size="tiny">
            Liquidation
          </Text>
        </StyledLegendContainer>
      </ChartBox>
    </>
  );
};

const StyledLTVContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  background-color: transparent;
  border-radius: 60px 60px;
  box-sizing: border-box;
  background: ${palette.red.main};
  border-radius: 60px;
`;

const StyledLTVPartialFill = styled.div<{
  background: string;
  width: number;
  initial?: boolean;
}>`
  height: 8px;
  width: ${({ width }) => width * 100}%;
  background-color: ${({ background }) => background};
  border-radius: ${({ initial }) => (initial ? "60px 0 0 60px" : "none")};
`;

const StyledLegendContainer = styled.div<{ offsetX: number; offsetY: number }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 110px;
  text-align: center;
  align-items: center;
  left: calc(${({ offsetX }) => offsetX * 100}% - 51px);
  top: ${({ offsetY }) => offsetY}px;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  font-feature-settings: "ss02" on, "liga" off;
`;

const StyledPointer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
const ChartBox = styled.div`
  position: relative;
  margin: 50px 0px;
`;
export default LoanToValueRatio;
