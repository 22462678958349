import React, { useState } from 'react';
import Layout from 'components/templates/Layout';
import { MarginContainer } from '../../Custody';
import CustodyNavigationWidget from '../../CustodyNavigationWidget';
import alternativeGridSystem from 'lib/theme/alternativeGridSystem';
import CustodyIncomeTransactionDetails from './CustodyIncomeTransactionDetails';
import CustodyIncomeSummary from './CustodyIncomeSummary';
import { useParams } from 'react-router-dom';
import {
    useGetAnnualIncomeDetailsQuery,
    useGetAnnualIncomeSummaryQuery,
    useGetAnnualIncomeTotalQuery,
    useGetIncomeReportCSVMutation,
} from 'state/store/reportApi';
import SharedLoadingState from '../SharedLoadingState';
import Modal from 'components/organisms/Modal';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import { SizeNamesEnum, TypesNamesEnum } from 'enums/Button.enum';
import { Download } from 'assets/icons';
import WhiteSpinner from 'components/atoms/Spinner/WhiteSpinner';
import { toast, ToastType } from 'components/organisms/Toast';
import DocumentTitleRow from 'components/molecules/DocumentTitleRow';

const CustodyIncome: React.FC = () => {
    const { year } = useParams();
    const numericYear = year ? parseInt(year, 10) : new Date().getFullYear();
    const { data: transactions, isLoading: isDetailsLoading } =
        useGetAnnualIncomeDetailsQuery(numericYear);
    const { data: totalIncome, isLoading: isTotalLoading } =
        useGetAnnualIncomeTotalQuery(numericYear);
    const { data: summaryData, isLoading: isSummaryLoading } =
        useGetAnnualIncomeSummaryQuery(numericYear);
    const isLoading = isDetailsLoading || isTotalLoading || isSummaryLoading;
    const [openModal, setOpenModal] = useState(false);
    const [reportCsv, reportCsvMeta] = useGetIncomeReportCSVMutation();

    const getCSV = async () => {
        try {
            await reportCsv(numericYear).unwrap();
            toast.show({
                type: ToastType.Success,
                title: 'File download successfully',
                content: 'Check your download folder',
            });
            setOpenModal(false);
        } catch (error) {
            toast.show({
                type: ToastType.Fail,
                title: 'Download failed',
                content: 'Please try again',
            });
        }
    };

    if (isLoading) return <SharedLoadingState />;

    return (
        <Layout customGrid={alternativeGridSystem}>
            <MarginContainer id='pdf-area'>
                <Modal
                    visible={openModal}
                    onClose={() => setOpenModal(false)}
                    header='Download Income Report'
                >
                    <ModalBody>
                        <ModalDescription>
                            Download income report for {year}
                        </ModalDescription>
                        <ButtonRow>
                            <Button
                                buttonType={TypesNamesEnum.SECONDAY}
                                size={SizeNamesEnum.EXTRA_LARGE_PLUS}
                                label='Cancel'
                                onClick={() => setOpenModal(false)}
                            />
                            <Button
                                isLoading={reportCsvMeta.isLoading}
                                buttonType={TypesNamesEnum.ACCENT}
                                size={SizeNamesEnum.EXTRA_LARGE_PLUS}
                                label='Download'
                                onClick={getCSV}
                            />
                        </ButtonRow>
                    </ModalBody>
                </Modal>
                <NavigationContainer>
                <CustodyNavigationWidget>
                    <HeaderContainer>
                        <DocumentRowWrapper>
                            <DocumentTitleRow isLoading={isLoading} title='Income' />
                        </DocumentRowWrapper>
                        <DownloadButton onClick={() => setOpenModal(true)}>
                            {reportCsvMeta.isLoading ? (
                                <WhiteSpinner />
                            ) : (
                                <>
                                    <Download color='white' size={15} />
                                    <span>Download</span>
                                </>
                            )}
                        </DownloadButton>
                    </HeaderContainer>
                    <CustodyIncomeSummary
                        summaryData={summaryData}
                        year={numericYear}
                        totalIncome={totalIncome}
                    />
                    <CustodyIncomeTransactionDetails
                        transactions={transactions}
                    />
                </CustodyNavigationWidget>
                </NavigationContainer>
            </MarginContainer>
        </Layout>
    );
};

const NavigationContainer = styled.div`
    position: relative;
    width: 100%;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
`;

const DownloadButton = styled.button`
    background-color: #6f2acd80;
    border: none;
    border-radius: 6px;
    font-size: 80%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease-in;

    &:hover {
        background-color: #292749;
    }

    @media print {
        display: none;
    }
`;

const DocumentRowWrapper = styled.div`
    margin-right: 10px;
    width: 100%;
`

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2vh;
    padding: 2vh 2vw;
`;

const ModalDescription = styled.p``;

const ButtonRow = styled.div`
    display: flex;
    justify-content: stretch;
    gap: 1vw;
`;

export default CustodyIncome;