import {WithdrawRequest} from './withdrawApi';
import {emptySplitApi as api} from './emptyApi';
import {YearMonth} from './statementsApi';
import moment from 'moment';
import { AssetAmountPair } from 'interfaces/AssetAmountPair.interface';

export const downloadCsvResult = (data: Blob, filename: string) => {
  var hiddenElement = document.createElement('a');
  var url = window.URL || window.webkitURL;
  var blobPDF = url.createObjectURL(data);
  hiddenElement.href = blobPDF;
  hiddenElement.target = '_blank';
  hiddenElement.download = filename;
  hiddenElement.click();
  hiddenElement.remove();
};

export const custodyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCustodyBalance: build.query<CustodyBalance[], null>({
      query: () => ({url: `/custody/balances?all=true`}),
    }),
    getCustodyBalanceWithdraw: build.query<CustodyBalance[], void>({
      query: () => ({ url: `/custody/balances?filter=WITHDRAW` }),
    }),
    getCustodyAssetsForDeposit: build.query<string[], null>({
      query: () => ({url: `/custody/deposit/assets`}),
    }),
    getCustodyWithdrawAssets: build.query<string[], null>({
      query: () => ({url: `/custody/withdraw/assets`}),
    }),
    getCustodyWithdrawNetworks: build.query<CustodyNetwork[], string>({
      query: (asset) => ({url: `/custody/withdraw/assets/${asset}/networks`}),
    }),
    getCustodyDepositNetworks: build.query<CustodyNetwork[], string>({
      query: (asset) => ({url: `/custody/deposit/assets/${asset}/networks`}),
    }),
    createCustodyWallet: build.mutation<CustodyWallet, AssetNetworkPair>({
      query: (body) => ({url: `/custody/wallet/address`, method: 'POST', body}),
    }),
    createCustodyWithdrawRequest: build.mutation<WithdrawRequest, CustodyWithdrawRequestBody>({
      query: (body) => ({url: `/custody/withdraw/request`, method: 'POST', body}),
    }),
    sendEmailVerification: build.mutation<WithdrawRequest, string>({
      query: (withdrawRequestId) => ({url: `/custody/withdraw/email-start/${withdrawRequestId}`, method: 'POST'}),
    }),
    sendCustodyUsernameAndPassword: build.mutation<UsernameAndPasswordCustodyResponse, OtpStart>({
      query: (body) => ({url: `/custody/withdraw/otp-start`, method: 'POST', body}),
    }),
    validateCustodyOTPCode: build.mutation<WithdrawRequest, OtpValidateRequest>({
      query: ({withdrawRequestId, mfaToken, otpCode}) => ({
        url: `/custody/withdraw/otp-validate/${withdrawRequestId}`,
        method: 'POST',
        body: {mfaToken, otpCode},
      }),
    }),
    getRateOfReturns: build.query<RateOfReturn[], string>({
      query: (strategyIdentifier) => ({url: `/custody/defi/strategy/${strategyIdentifier}/returns`}),
    }),
    validateCustodyEmailCode: build.mutation<WithdrawRequest, EmailValidateRequest>({
      query: ({withdrawRequestId, code}) => ({url: `/custody/withdraw/email-validate/${withdrawRequestId}`, method: 'POST', body: {code}}),
    }),
    getCustodyWithdrawInfo: build.query<WithdrawInfoResponse, AssetNetworkPair>({
      query: (pair) => ({url: `/custody/withdraw/info?asset=${pair.asset}&network=${pair.network}`, method: 'GET'}),
    }),
    getCustodyDepositInfo: build.query<AssetNetworkInformation, AssetNetworkPair>({
      query: (pair) => ({url: `/custody/deposit/info?asset=${pair.asset}&network=${pair.network}`, method: 'GET'}),
    }),
    getCustodyTransactions: build.query<CustodyTransactionsResponse, CustodyTransactionArguments>({
      query: ({page}) => ({url: `/custody/transactions?page=${page}&size=5`}),
    }),
    getCustodyTransactionType: build.query<CustodyTransactionsResponse, CustodyTransactionWithActionsArguments>({
      query: (body) => ({url: `/custody/transactions`, method: 'POST', body}),
    }),
    getCustodyTransactionsPending: build.query<PendingTransaction[], null>({
      query: () => ({url: `/custody/transactions/pending`}),
    }),
    getCustodyInvoice: build.query<CustodyInvoice, YearMonth>({
      query: ({year, month}) => ({url: `/custody/invoice/${year}-${month}`}),
    }),
    getCustodyBalanceCSV: build.mutation<any, void>({
      queryFn: async (_, _api, _extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/custody/balances/csv`,
          responseHandler: (response) => response.blob(),
        });
        const dateStr = formatLocalDate(new Date());
        downloadCsvResult(result.data as Blob, `balances_${dateStr}.csv`);
        return {data: null};
      },
    }),
    getCustodyTransactionsCSV: build.mutation<any, DateRange>({
      queryFn: async ({from, to}, _api, _extraOptions, baseQuery) => {
        const fromStr = formatLocalDate(from);
        const toStr = formatLocalDate(to);
        const result = await baseQuery({
          url: `/custody/transactions/csv?from=${fromStr}&to=${toStr}`,
          responseHandler: (response) => response.blob(),
        });
        downloadCsvResult(result.data as Blob, `transactions_${fromStr}_${toStr}.csv`);
        return {data: null};
      },
    }),
    getAvailableMonthlyDocuments: build.query<MonthlyDocumentsAvailability[], void>({
      query: () => ({url: `/custody/documents/monthly`}),
    }),
    getAvailableAnnualDocuments: build.query<AnnualDocumentsAvailability[], void>({
      query: () => ({url: `/custody/documents/annual`}),
    }),
    getMilestones: build.query<Milestones, void>({
      query: () => ({url: `/milestones`}),
    }),
    getBankInfo: build.query<any, void>({
      query: () => ({url: `/custody/bank/recent-info`}),
    })
  }),
  overrideExisting: false,
});

export interface BorrowHealthRequest {
  asset: string,
  network: string,
  amount: string,
  transactionType: string
}

export interface UsernameAndPasswordCustodyResponse {
  accessToken: string;
  barcodeUri: string;
  secret: string;
  otpIsActive: string;
}

export interface EmailValidateRequest {
  code: string;
  withdrawRequestId: number;
}

export interface OtpStart {
  username: string;
  password: string;
}

export interface RateOfReturn {
  month: string,
  rate: string
}

export interface OtpValidateRequest {
  mfaToken: string;
  otpCode: string;
  withdrawRequestId: number;
}

export interface CustodyWallet {
  accountUID: string;
  crypto: string;
  network: string;
  address: string;
  tag: string;
  legacyAddress: string;
  depositNotes: string[];
  minConfirmations: number;
  custodyBps: number;
}

export interface WithdrawInfoResponse {
  balanceWithEstimatedFee: {
    availableAmountInAsset: AssetAmountPair;
    totalAmountInAsset: AssetAmountPair;
    availableAmountInFeeAsset: AssetAmountPair;
    network: string;
    estimatedFee: AssetAmountPair;
  };
  minWithdrawal: AssetAmountPair
  maxWithdrawal: AssetAmountPair
  maxUnverifiedWithdrawal: AssetAmountPair
}

export interface BalanceExplanation {
  availableBalance: AssetAmountPair;
  balanceHolds: AssetAmountPair;
  gasReserve: AssetAmountPair;
  networkFeeHold: AssetAmountPair;
  billingReserve: AssetAmountPair;
}

export interface AssetNetworkInformation {
  availableAmountInAsset: AssetAmountPair;
  totalAmountInAsset: AssetAmountPair;
  availableAmountInFeeAsset: AssetAmountPair;
  network: string;
  estimatedFee: AssetAmountPair;
  explanation: BalanceExplanation;
}

export interface CustodyWithdrawRequestBody {
  amount: string;
  whitelistAddressId: number;
  currency: string;
  network: string;
}

export interface AssetNetworkPair {
  network: string;
  asset: string;
}

export interface CustodyTransactionArguments {
  page: number;
}

export interface CustodyTransactionWithActionsArguments {
  pageRequest: number;
  sizeRequest: number;
  actionNameSelections?: string[];
  loanProvider?: string;
}

export type CustodyNetworkType = 'BASE_ASSET' | 'ERC20' | 'BEP20';

export const CustodyNetworkMap = {
  BASE_ASSET: 'BASE ASSET',
  ERC20: 'ERC20',
  BEP20: 'BEP20',
};

export interface CustodyTransaction {
  dateTime: string;
  networkName: string;
  actionName: string;
  abraTxUID: string;
  transactionCategory: string;
  amount: AssetAmountPair;
  networkFee: AssetAmountPair;
  networkFeeConvenienceRate: {
    baseCurrency: string;
    quoteCurrency: string;
    rate: string;
  };
  amountConvenienceRate: {
    rate: string;
  };
  blockchainHash: string;
  blockchainURL: string;
  strategyName: string;
  invoiceYearMonth: string;
}

export interface CustodyPageable {
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

export interface CustodyTransactionsResponse {
  content: CustodyTransaction[];
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

export interface PendingTransaction {
  dateTime: string;
  abraTxUID: string;
  networkName: string;
  actionName: string;
  status: string;
  amount: AssetAmountPair;
  blockchainHash: string;
  blockchainURL: string;
  cancelable?: boolean;
  strategyName?: string;
}



export interface CustodyNetwork {
  network: string;
  name: string;
  addressRegex: string;
  requiresMemo: boolean;
  memo: string;
  description: string;
}

export interface CustodyBalance {
  network: string;
  networkType: string;
  networkName: string;
  balanceCurrency: string;
  availableBalance: AssetAmountPair;
  currentBalance: AssetAmountPair;
  valueCurrency: string;
  currentPrice: {
      baseCurrency: string;
      quoteCurrency: string;
      rate: string;
      live: boolean;
      timestamp: string;
  };
  costBasis: AssetAmountPair;
  cryptoAddress: string;
  dashboardUrl: string;
  unrealizedPercentage: string;
  availableValue: AssetAmountPair;
  unrealizedAmount: AssetAmountPair;
  realizedYTD: AssetAmountPair;
  currentValue: AssetAmountPair;
}

export interface CustodyInvoice {
  identifier: string;
  issuedDate: string;
  range: {
    startInclusive: string;
    endExclusive: string;
  };
  accountNumber: string;
  feeSummaries: FeeSumary[];
  feeDetails: CustodyInvoiceFeeDetails[];
  payments: CustodyInvoicePayment[];
}

export interface FeeSumary {
  managementFee: AssetAmountPair;
  paymentStatus: string;
  previousAccumulatedManagementFee: AssetAmountPair;
  previousMonthTotalManagementFee: AssetAmountPair;
  totalManagementFee: AssetAmountPair;
}

export interface CustodyInvoiceFeeDetails {
  feeBasisPoints: string;
  feeType: string; // invest, custody
  feeDescription: string;
  defiStrategyIdentifier?: string;
  defiStrategyName?: string;
  averageBalance: AssetAmountPair;
  averageBillingBalance:AssetAmountPair,
  averageBillingBalanceValue:AssetAmountPair,
  feeAmount: AssetAmountPair
  feePercentage: string;
}

export interface MonthlyDocumentsAvailability {
  yearMonth: string;
  hasStatement: boolean;
  hasInvoice: boolean;
}

export interface AnnualDocumentsAvailability {
  year: string;
  hasIncome: boolean;
  hasGainLoss: boolean;
  hasExpenses: boolean;
}

export interface CustodyInvoicePayment {
  transactionUID: string;
  amount: AssetAmountPair;
  feeAmount: AssetAmountPair;
  network: string;
  billingAmount: AssetAmountPair;
  paymentStatus: string;
  paidAt: string;
  transactionHash: string;
}

export const formatLocalDate = (date: Date) => {
  return moment(date).format('YYYY-MM-DD');
};

export interface DateRange {
  from: Date;
  to: Date;
}

export interface Milestones {
  deposits: boolean,
  invested: boolean, 
  assetsBeyondReserve: boolean,
}

export interface CustodyBalanceResponse {
  custodyBalances?: CustodyBalanceDetail[]
  collateralBalances?: CustodyBalanceDetail[]
  availableForBorrowBalances?: CustodyBalanceDetail[]
  principalBalances?: CustodyBalanceDetail[]
}

export interface CustodyBalanceDetail {
  balance: Balance
  balanceValue: BalanceValue
  networkDescription: string
  network: string
  apy: string
}

export interface Balance {
  asset: string
  amount: string
}

export interface BalanceValue {
  asset: string
  amount: string
}

export const {
  useGetCustodyBalanceQuery,
  useGetMilestonesQuery,
  useGetCustodyBalanceWithdrawQuery,
  useGetCustodyTransactionsQuery,
  useGetCustodyBalanceCSVMutation,
  useGetCustodyTransactionsCSVMutation,
  useGetCustodyAssetsForDepositQuery,
  useCreateCustodyWalletMutation,
  useGetCustodyWithdrawAssetsQuery,
  useGetCustodyTransactionTypeQuery, 
  useGetRateOfReturnsQuery,
  useGetCustodyWithdrawNetworksQuery,
  useGetCustodyWithdrawInfoQuery,
  useCreateCustodyWithdrawRequestMutation,
  useGetCustodyTransactionsPendingQuery,
  useSendCustodyUsernameAndPasswordMutation,
  useValidateCustodyEmailCodeMutation,
  useValidateCustodyOTPCodeMutation,
  useGetCustodyDepositNetworksQuery,
  useGetCustodyDepositInfoQuery,
  useGetCustodyInvoiceQuery,
  useSendEmailVerificationMutation,
  useGetAvailableMonthlyDocumentsQuery,
  useGetAvailableAnnualDocumentsQuery,
  useLazyGetBankInfoQuery
} = custodyApi;
