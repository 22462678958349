import Layout from 'components/templates/Layout'
import React, { useEffect, useMemo, useState } from 'react'
import { MarginContainer } from './Custody'
import styled from 'styled-components'
import Widget from 'components/templates/Widget'
import { palette } from 'lib/theme'
import FormInputBox from 'components/atoms/FormInputBox'
import { Keys, useCreateKeyMutation, useDeleteKeyMutation, useLazyGetKeysQuery } from 'state/store/settingsApi'
import Loading from 'components/atoms/Loading'
import { SpinnerSizeEnum } from '@types/enums/Loading.enum'
import Button from 'components/atoms/Button'
import { SizeNamesEnum, TypesNamesEnum } from 'enums/Button.enum'
import { toast, ToastType } from 'components/organisms/Toast'
import Icon from 'components/atoms/Icon'
import { useNavigate } from 'react-router-dom'
import { PagesUrl } from 'lib/constants/config.constant'
import usePermissions from 'shared/usePermissions'

const Settings = () => {
  const [getKeys, keys] = useLazyGetKeysQuery()
  const [createKey, createdKey] = useCreateKeyMutation()
  const [deleteKey, deletedKey] = useDeleteKeyMutation()
  const [newKey, setNewKey] = useState<Record<string, string>>({})

  const navigate = useNavigate();
  const { canSeeSettingsPage, custodySettingsEnabledLoading } = usePermissions();

  useEffect(() => {
    if(!custodySettingsEnabledLoading ) {
      if(canSeeSettingsPage)
        getKeys()
      else
        navigate(`${PagesUrl.CUSTODY}`)
    } 
  }, [canSeeSettingsPage, custodySettingsEnabledLoading])
  
  const isLoading = useMemo(() => {
    return keys.isFetching || createdKey.isLoading || deletedKey.isLoading
  }, [keys, createdKey, deletedKey])

  const onCreateKey = async () => {
    const data = await createKey().unwrap()
    const {apiKeyInfo, apiKey} = data
    setNewKey({
      ...newKey,
      [apiKeyInfo.publicUid]: apiKey
    })
    
    getKeys()
  }

  const onDeleteKey = async (keyId?: string) => {
    if(keyId) {
      await deleteKey(keyId)
      getKeys()
    }
  }

  const onCopyKey = (value: string) => {
    navigator.clipboard.writeText(value)
    toast.show({
      type: ToastType.Success,
      title: "Value copied to clipboard!",
      content: ""
    });
  }

  const renderRow = (data: Keys | null, placeholder: string) => {
    const keyIsVisible = !!newKey[data?.publicUid!]
    const value = newKey[data?.publicUid!] || (data?.publicUid ? `${placeholder} (Only Visible At Time Of Creation)`: "Click Create To Generate A Key")
    return (
      <>
        <InputWrapper>
            <StyledInput disabled value={value}/> 
        </InputWrapper>
        <StyledButton 
          onClick={() => onDeleteKey(data?.publicUid)} 
          disabled={!data}
          buttonType={TypesNamesEnum.ACCENT}
          size={SizeNamesEnum.SMALL}
          label="Delete"
        />
        {keyIsVisible && 
          <Button
            onClick={() => onCopyKey(value)} 
            disabled={!newKey[data?.publicUid!]}
            buttonType={TypesNamesEnum.ACCENT}
            size={SizeNamesEnum.TINY}
          >
            <Icon name='Copy' />
          </Button>
        }
      </>
    )
  }

  return (
    <Layout>
      <MarginContainer>
        <Widget>
            <TokenSummaryTitle>API Key Management</TokenSummaryTitle>
              <StyledFormInputBox>
                {!isLoading && 
                  <>
                    <Row>
                      {renderRow(keys.data ? keys.data[0] : null, "First Key")}
                    </Row>
                    <Row>
                      {renderRow(keys.data ? keys.data[1] : null, "Second Key")}
                    </Row>
                    <Row>
                      {!isLoading && (keys.data || []).length < 2 ? 
                        <Button 
                          onClick={() => onCreateKey()}
                          buttonType={TypesNamesEnum.ACCENT}
                          size={SizeNamesEnum.DEFAULT}
                          label="Create"
                        /> : 
                        <span>You can only have up to two keys</span>
                      }
                    </Row>
                  </>
                }
                {isLoading && 
                  <Loading 
                    showText={false}  
                    size={SpinnerSizeEnum.LARGE}
                  />
                }
              </StyledFormInputBox>
        </Widget>
      </MarginContainer>
    </Layout>
  )
}

const Row = styled.div`
  padding: 10px 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const StyledFormInputBox = styled(FormInputBox)`
  padding: 10px 0px;
  flex-direction: column;
`;


const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: ${palette.white.main};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 18px;
  margin-bottom: 6px;
  letter-spacing: -0.5px;
`;

const StyledInput = styled.input`
    background-color: transparent;
    color: ${palette.white.main};
    min-width: 32ch;
    width: 90%;
    border: unset;
    padding: 0px 8px 0px 8px;
    flex-grow: 1;
    display: flex;
    -moz-appearance: textfield;
    appearance: textfield;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    justify-items: center;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &:focus {
        outline: none;
    }
`;  

const InputWrapper = styled.div`
  margin-right: 12px;
  border: 1px solid ${palette.white.main};
  padding: 8px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 90%;
  background-color: ${palette.darkBackgroundContrast.light10};
`

const StyledButton = styled(Button)`
  margin-right: 10px;
`;
export default Settings
