import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import RadioButton from 'components/atoms/RadioButton';
import { TypesNamesEnum } from 'enums/Button.enum';
import React, { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { emailValidation, symbolsValidation } from 'shared';
import { CustodyUserRequest } from 'state/store/onboardingApi';
import styled from 'styled-components';
import ReCAPTCHA from "react-google-recaptcha";
import { ToastType, toast } from 'components/organisms/Toast';
import Turnstile from 'react-turnstile';

interface Props {
  next: () => void;
  information: CustodyUserRequest;
  setInformation: (email: CustodyUserRequest) => void;
  setTurnstileToken: (token: string) => void;
  setCaptchaToken: (token: string) => void;
  captchaToken: string;
  turnstileToken: string;
}

const RECAPTCHA_API_KEY = process.env.RECAPTCHA_API_KEY || '';
const RECAPTCHA_ENABLED = false; // DISABLED. process.env.RECAPTCHA_ENABLED !== 'false';
const TURNSTILE_SITE_KEY = process.env.TURNSTILE_SITE_KEY || '';
const TURNSTILE_ENABLED = process.env.TURNSTILE_ENABLED !== 'false';

const RegisterBasicInformation: FC<Props> = ({ 
  next, 
  information, 
  setInformation,
  setCaptchaToken,
  captchaToken,
  setTurnstileToken,
  turnstileToken
}) => {
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [emailError, setEmailError] = useState(false);
  const navigate = useNavigate();  
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  
  const handleTurnstileVerify = (token: string) => {
    setTurnstileToken(token);
  };
  
  const handleCaptchaChange = (token: string | null) => {
    if (token) {
      setCaptchaToken(token);
    } else {
      toast.show({
        type: ToastType.Fail,
        title: "CAPTCHA verification failed",
        content: 'Please try again',
      });
    }
  };

  const handleTurnstileError = () => {
    setTurnstileToken('');
    toast.show({
      type: ToastType.Fail,
      title: "Verification failed",
      content: 'Please try again',
    });
  };

  const back = () => {
    navigate(-1);
  };

  const validateEmail = (email: string) => {
    const isValid = symbolsValidation('@', email) && emailValidation(email);
    setEmailError(!isValid);
  };

  const redirectToAcmApplication = () => {
    let acmApplicationUrl = 'https://www.abra.com/acm-application';
    window.location.href = acmApplicationUrl;
  };

  const handleNext = () => {
    if (information.organizationType === 'INSTITUTION') {
      redirectToAcmApplication();
    } else {
      next();
    }
  };

  const isNextDisabled = () => {
    return !!emailError || !information.email
      || TURNSTILE_ENABLED && !turnstileToken
      || RECAPTCHA_ENABLED && !captchaToken;
  };

  return (
    <RegisterBasicInformationBox>
      <EmailBox>
        <p>Please provide your email address</p>
        <Input
          label='Email'
          className='input'
          inputProps={{
            value: information.email,
            id: 'email',
            name: 'email',
            type: 'email',
            onChange: (event: any) => {
              validateEmail(event?.target?.value);
              setEmailErrorMessage('');
              setInformation({ ...information, email: event.target.value ?? '' });
            },
            onBlur: (event: any) => {
              setEmailErrorMessage(emailError ? 'invalid email format' : '');
              validateEmail(event?.target?.value);
            },
          }}
          errorMessage={emailErrorMessage}
          dismissAlert={() => setEmailErrorMessage('')}
          dismissAlertButton={false}
        />
      </EmailBox>
      <div>
        <RadioGroup>
          <p>Are you a U.S. Resident or Tax Payer?</p>
          <RadioButton
            size={30}
            name='usResident'
            text='Yes'
            value='Yes'
            checked={information.usResident}
            onChange={() => setInformation({ ...information, usResident: true })}
          />
          <RadioButton
            name='usResident'
            text='No'
            value='No'
            checked={!information.usResident}
            onChange={() => setInformation({ ...information, usResident: false })}
          />
          <p>Are you an Individual or an Organization?</p>
          <RadioButton
            size={30}
            name='clientType'
            text='Individual'
            value='Individual'
            checked={information.organizationType === 'INDIVIDUAL'}
            onChange={() => setInformation({ ...information, organizationType: 'INDIVIDUAL' })}
          />
          <RadioButton
            name='clientType'
            text='Organization'
            value='INSTITUTION'
            checked={information.organizationType === 'INSTITUTION'}
            onChange={() => setInformation({ ...information, organizationType: 'INSTITUTION' })}
          />
        </RadioGroup>
      </div>

      {RECAPTCHA_ENABLED && (
        <CaptchaBox>
          <ReCAPTCHA
            sitekey={RECAPTCHA_API_KEY}
            onChange={handleCaptchaChange}
            ref={recaptchaRef}
          />
        </CaptchaBox>
      )}
      <Grid>
        <Button 
          buttonType={TypesNamesEnum.SECONDAY} 
          label='Back' 
          onClick={back} 
          type='button' 
        />
        <Button 
          buttonType={TypesNamesEnum.ACCENT} 
          label='Next' 
          onClick={handleNext} 
          type='submit' 
          disabled={isNextDisabled()} 
        />
      </Grid>
      {TURNSTILE_ENABLED && (
          <CaptchaBox>
            <Turnstile
              sitekey={TURNSTILE_SITE_KEY}
              onVerify={handleTurnstileVerify}
              onError={handleTurnstileError}
              onExpire={() => setTurnstileToken('')}
              theme="auto"
              refreshExpired="auto"
              appearance="interaction-only"
            />
          </CaptchaBox>
        )}
    </RegisterBasicInformationBox>
  );
};

const CaptchaBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Grid = styled.div`
  display: grid;
  gap: 10px;
  height: 50px;
  grid-template-columns: 1fr 1fr;
  Button {
    font-weight: 400;
    font-size: 1.1rem;
  }
`;

const RegisterBasicInformationBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmailBox = styled.div`
  display: flex;
  flex-direction: column;
  gap:15px;
`
const RadioGroup = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 2vh;
  margin-top: 0.5vh;
  margin-bottom: 3vh;
`;

export default RegisterBasicInformation;
