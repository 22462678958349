import { TypesNamesEnum, SizeNamesEnum } from "@types/enums/Button.enum.ts";
import { SpinnerSizeEnum } from "@types/enums/Loading.enum";
import palette from "lib/theme/palette";

export const IDENTITY_STATUS_APPROVED = "Approved";

export const DefaultSettings = {
  DEFAULT_CRYPTO_ASSET: "XATC",
  DEFAULT_PAY_WITH_CURRENCY: "USDCT",
  DEFAULT_UNSET_AVATAR_URL: "/photos/thumb/missing.png",
  RECORDS_PER_PAGE: 5,
  RECORDS_PER_PAGE_FULL_SCREEN: 20,
  RECORDS_GET_ALL: 999999,
  DEFAULT_TABLE_SORTING_FIELD: "marketCap",
  API_REQUEST_ATTEMPTS: 5,
  API_REQUEST_ATTEMPT_DELAY: 1000,
  API_COUNTRY_ICON:
    "https://purecatamphetamine.github.io/country-flag-icons/3x2/<<code>>.svg",
  BANK_DEPOSIT_SETUP_REQUIRED: "SetupRequired",
  IMAGE_TYPE_SUPPORTED: ["image/gif", "image/jpeg", "image/png"],
  IMAGE_MAX_SIZE: 10000000,
  PIE_CHART_MINIMUN_VALUE: 1.7,
  DEFAULT_BANK_IMAGE: "default_bank_icon",
  HOLDINGS_US_ACCOUNT: ["TUSD", "XTUSD", "XTUSDT"],
  NATIVE_CRYPTO: "NC",
  TOTAL_COLUMNS_DESKTOP: 12,
  TOTAL_COLUMNS_MOBILE: 6,
  MAX_COLUMNS_WIDTH: "4.2rem",
  COMPLETED_TRANSACTIONS_HAS_SUBTITLE: [
    "OrderCredit",
    "ConvertCurrencyCredit",
    "OrderDebit",
    "ConvertCurrencyDebit",
  ],
  COMPLETED_TRANSACTIONS_HAS_OTHER_AMOUNT: [
    "collateral_liquidation_ltv_threshold",
    "collateral_liquidation",
    "collateral_liquidation_missed_interest_payment",
    "collateral_liquidation_missed_principal_repayment",
    "collateral_liquidation_expired_collateral_call",
  ],
};
export const BankType = {
  PLAID: "plaid",
  WIRE: "wireDeposit",
  BANK: "bank",
  WIREINTDEPOSIT: "wireIntDeposit",
  WIREINTWITHDRAW: "wireIntWithdraw",
  WIREWITHDRAW: "wireWithdraw",
};

export const PagesUrl = {
  HOME: "/",
  WITHDRAW: "/withdraw",
  CUSTODY: "/custody",
  DOCUMENT: '/document',
  STATEMENT: "/statement",
  INVOICE: "/invoice",
  INCOME: "/income",
  TAX: "/tax",
  ADDRESSES: "/addresses",
  AUTHENTICATION: "/auth",
  SIGN_UP: "/sign-up",
  SIGN_IN: "/sign-in",
  LOG_IN: "/login",
  RESET_PASSWORD: "/reset-password",
  CREATE_PASSWORD: "/create-password",
  TWO_FACTOR_AUTH: "/2fa-verification",
  SETUP_PASSWORD: "/setup-password",
  ENABLE_2FA: "/enable-2fa",
  TURN_ON_2FA: "/turn-on-2fa",
  CONFIRM_PASSWORD: "/confirm-password",
  ADD_MONEY: "/add-money",
  WITHDRAW_MONEY: "/withdraw-money",
  PLAID: "/plaid",
  TRANSACTION: "/transaction",
  SUBMIT_BANK_DETAIL: "/submit-bank-detail",
  KYC_PHONE_VERIFICATION: "/kyc/phone-verification",
  PROFILE: "/profile",
  EDIT_PROFILE: "/edit-profile",
  TRADE: "/trade",
  PRICE_ALERTS: "/price-alerts",
  ALL_CRYPTO: "/trade/all-crypto",
  EARN: "/earn",
  BORROW: "/borrow",
  CLOSED_LOANS: "/borrow/closed-loans",
  EARN_ALL_CRYPTO: "/earn/all-crypto",
  EARN_COMPLETED_TRANSACTIONS: "/earn/completed-transactions",
  PERX: "/perx",
  REFERRAL: "/referral",
  PRIME_TRUST: "/prime-trust/*",
  PERX_DETAILS: "/perx-details",
  LOAN_DETAILS: "/loan-details",
  CREDIT: "/credit",
  NOTIFICATIONS: "/notifications",
  BOOST: "/boost",
  DEPOSIT_DETAILS: "/depositdetails",
  CLIENT_USERS: "/client-users",
  ONBOARDING: "/auth/onboarding",
  DEFI_BORROW: "/defi-borrow",
  GAIN_LOSS: "/gain-loss",
  EXPENSES: "/expenses",
  SETTINGS: "/settings"
};

export const PrimeTrustCompletedInfo = {
  heading: "Complete your Prime Trust application",
  paragraph:
    "Do a quick identity check with Prime Trust to unlock all of Abra's powerful exchange features. All you need is:",
  bullets: [
    "A photo of your valid government ID",
    "Basic identifying information",
    "Your address",
    "Your government or tax ID number",
  ],
};
export const PrimeTrustEarnBorrowInfo = {
  heading: "Activate Boost & Borrow",
  paragraph:
    "Want to start boosting interest? You need to complete identity checks with Prime Trust first. All you'll need is:",
  bullets: [
    "A photo of your valid government ID",
    "Basic identifying information",
    "Your address",
    "Your government or tax ID number",
  ],
};
export const Schedule = [
  { id: "just_once", label: "Just once" },
  { id: "weekly", label: "Weekly" },
  { id: "twice_month", label: "Twice a month" },
  { id: "monthly", label: "Monthly" },
];
export const TransactionsTypes = {
  EXCHANGE: "exchange",
  BANK_DEPOSIT: "bank_deposit",
};
export const TransactionsStatus = {
  ENABLED: "enabled",
  DISABLED: "disabled",
};

export const ButtonSize = {
  [SizeNamesEnum.TINY]: {
    width: "70",
    height: "40",
    measurement: "px",
  },
  [SizeNamesEnum.DEFAULT]: {
    width: "128",
    height: "48",
    measurement: "px",
  },
  [SizeNamesEnum.SMALL]: {
    width: "117",
    height: "40",
    measurement: "px",
  },
  [SizeNamesEnum.MEDIUM]: {
    width: "248",
    height: "48",
    measurement: "px",
  },
  [SizeNamesEnum.SMALL]: {
    width: "117",
    height: "40",
    measurement: "px",
  },
  [SizeNamesEnum.MEDIUM]: {
    width: "248",
    height: "48",
    measurement: "px",
  },
  [SizeNamesEnum.MEDIUM_MODAL]: {
    width: "212",
    height: "48",
    measurement: "px",
  },
  [SizeNamesEnum.BLOCK]: {
    width: "100",
    height: "40",
    measurement: "%",
  },
  [SizeNamesEnum.BLOCK_LARGE]: {
    width: "100",
    height: "48",
    measurement: "%",
  },
  [SizeNamesEnum.NORMAL]: {
    width: "336",
    height: "48",
    measurement: "px",
  },
  [SizeNamesEnum.DEFAULT_LARGE]: {
    width: "426",
    height: "48",
    measurement: "px",
  },
  [SizeNamesEnum.SMALL_LARGE]: {
    width: "426",
    height: "40",
    measurement: "px",
  },
  [SizeNamesEnum.EXTRA_LARGE]: {
    width: "auto",
    height: "48",
    measurement: "",
  },
  [SizeNamesEnum.EXTRA_LARGE_PLUS]: {
    width: "436",
    height: "48",
    measurement: "px",
  },
};

export const ButtonType = {
  [TypesNamesEnum.PRIMARY]: {
    dafaultBackgroundColor: palette.gray.main,
    dafaultFontColor: palette.black.main,
    disableBackgroundColor: palette.black.light20,
    disableFontColor: palette.black.light15,
    hoverBackgroundColor: palette.white.light20,
    hoverFontColor: palette.black.main,
  },
  [TypesNamesEnum.SECONDAY]: {
    dafaultBackgroundColor: palette.accent.light25,
    dafaultFontColor: palette.white.main,
    disableBackgroundColor: palette.accent.light10,
    disableFontColor: palette.white.light20,
    hoverBackgroundColor: palette.secondary.auxiliar,
    hoverFontColor: palette.white.main,
  },
  [TypesNamesEnum.ACCENT]: {
    dafaultBackgroundColor: palette.accent.light100,
    dafaultFontColor: palette.white.main,
    disableBackgroundColor: palette.accent.light10,
    disableFontColor: palette.darkBackgroundContrast.light20,
    hoverBackgroundColor: palette.accent.auxiliar,
    hoverFontColor: palette.white.main,
  },
};

export const LoadingSize = {
  [SpinnerSizeEnum.DEFAULT]: {
    width: "40px",
    height: "40px",
  },
  [SpinnerSizeEnum.INPUT]: {
    width: "23px",
    height: "23px",
  },
  [SpinnerSizeEnum.BUTTON]: {
    width: "22px",
    height: "23px",
  },
  [SpinnerSizeEnum.LARGE]: {
    width: "140px",
    height: "140px",
  },
  [SpinnerSizeEnum.SORTING]: {
    width: "11px",
    height: "11px",
  },
};

export const IconsAllOptions = {
  BANK: "assets/svg/add_money_ACH.svg",
  BANK_PHTELLER: "assets/svg/add_money_wire.svg",
  BANK_PH: "assets/svg/add_money_ACH.svg",
  WIRE: "assets/svg/add_money_wire.svg",
  VISAMC: "assets/svg/add_money_Visa.svg",
  CRYPTO: "assets/svg/add_money_crypto.svg",
  "7ELEVEN": "assets/svg/7eleven.svg",
  CASH: "assets/svg/cash.svg",
  INT_WIRE: "assets/svg/add_money_wire.svg",
  MARKETPLACE_CRYPTO: "assets/svg/add_money_Visa.svg",
};

export const FeeTypes = {
  "deduct-fee": "sustract",
  "add-fee": "add",
};
export const SEVEN_ELEVEN_CONF_KEY = "7eleven_instructions_url";
export const USER_CONF_PARAMS = [
  "us_bank_deposits_enabled",
  "bank.us.ach.pt.monthly.deposit.limit",
  "blockchain_options",
  "contact_us_form_url",
  "php_withdrawal_limit",
  "loan.hello_sign.enabled",
  "report.transaction_history.enabled",
  "rewards.tier.benefits.ignite",
  "cboe_disclaimer",
  "transaction_min_global_usd",
  "ph_send_recipient_context",
  "bank.us.wire.pt.deposit_settlement_days",
  "altcoins_info_url",
  "loans.funding.days.text",
  "bank.int.wire.pt.withdrawal.minimum",
  "us_bank_withdrawals_enabled",
  "kyc.withdraw.enabled",
  "rewards.tier.benefits.cruise",
  "simplex_subdomain_url",
  "amex.card_deposit.revoked_signing_keys",
  "add_money_call_to_action_enabled",
  "limit.us.usd.amex.card_deposit.seven_days",
  "us_person.question.enabled",
  "us_person.question.html.faq.url",
  "rewards.tier.benefits.launch",
  "rewards.history.pending_text",
  "loans.user.max_per_asset",
  "face_id.enabled",
  "bank.us.wire.pt.deposit_days_text",
  "simplex_return_url",
  "ach_withdraw_hold_days",
  "equity.us.market.close_threshold.minutes",
  "phl_fees_v2_abra_minimum_percentage",
  "limit.us.usd.amex.card_deposit.thirty_days",
  "banner.portfolio.text",
  "bitw10_description_url",
  "rewards.referral.completed.text",
  "assets.native_hosted.due_days",
  "bank.us.ach.pt.enabled",
  "assets.native_hosted.ineligible.list",
  "achbank.fee.text",
  "crypto_education_learn_more_url",
  "us_person.question.html.title",
  SEVEN_ELEVEN_CONF_KEY,
  "assets.native_hosted.ineligible.url",
  "report.cost_basis_2017.enabled",
  "visamc.card_fee.text",
  "backup_reminder_delay",
  "kyc.enabled",
  "us_person.question.html.body",
  "rewards.enabled",
  "loan_options.ltv.starting_at_rate",
  "allow_withdrawal_to_contracts",
  "wallet_recovery_education.display_in_signup",
  "add_ach_option",
  "amex.card_deposit.fee_url",
  "user_select_currency_onsignup",
  "report.transaction_history_2017.enabled",
  "bank.us.ach.pt.monthly.withdrawal.limit",
  "equity.us.market.open_threshold.minutes",
  "limit.us.usd.amex.card_deposit.twenty_four_hours",
  "assets.native_hosted.ineligible.title",
  "bank.int.wire.pt.deposit_days_text",
  "litecoin_migration_learn_more_url",
  "user_max_wallets",
  "price_alerts.enabled",
  "bank.int.wire.pt.deposit_settlement_days",
  "altcoin_sell_enabled",
  "assets.native_hosted.migration.enabled",
  "teller_matching_country_exclude_list",
  "transactions.rate_lock_seconds_exchange",
  "litecoin_migration_delay_allowed",
  "assets.native_hosted.url",
  "market_data.charts.enabled",
  "exchange_delay_alert_enabled",
  "convenience_balance_enabled",
  "transactions.us_person.blocked_fiat_indexes",
  "loans.enabled",
  "assets.native_hosted.text",
  "assets.native_hosted.ineligible.text",
  "altcoin_buy_enabled",
  "bank.us.wire.pt.withdrawal_days_text",
  "support_url",
  "kyc.max_skip",
  "change_country_options",
  "rewards.tier.benefits.baseline",
  "us_person.question.html.button.no",
  "bank.us.ach.pt.daily.deposit.limit",
  "bank.us.wires.deposit_settlement_days",
  "us_person.question.html.button.yes",
  "referrals.enabled",
  "unconfirmed_sends_enabled",
  "ph_bank_deposits_enabled",
  "amex.card_deposit.supported_countries",
  "bank.us.ach.pt.deposit_days_text",
  "bank_country_options",
  "bank.us.ach.pt.daily.withdrawal.limit",
  "rewards.referral.completed.text_list",
  "ph_bank_withdrawals_enabled",
  "us_person.question.html.faq.url.label",
  "phl_fees_v2_abra_minimum",
  "rewards.tier.benefits.toTheMoon",
  "recurring_transactions.enabled",
  "price_alerts.max_per_user",
  "user_second_wallet_enabled",
  "limit.us.usd.amex.card_deposit.per_transaction",
  "recurring_transactions.exchange.amount.limit",
  "rewards.referral.enabled",
  "assets.native_hosted.title",
  "bank.us.wire.pt.withdrawal.minimum",
  "orders.enabled",
  "2fa.enabled",
  "crypto_migration_enabled_currencies",
  "phl_fees_v2_abra_split",
  "bank.int.wire.pt.enabled",
  "rewards.tier.benefits.orbit",
  "bank.us.wire.pt.enabled",
  "wallet_recovery_education.skips",
  "simplex_donthaveone_url",
];

export const Days = {
  0: "sun",
  1: "mon",
  2: "tue",
  3: "wed",
  4: "thu",
  5: "fri",
  6: "sat",
};

export const API_URL_GET_TRANSACTIONS = "getTransactions";
export const API_URL_GET_OPEN_ORDERS = "getOpenOrders";
export const API_URL_GET_LOANS = "getLoans";

export const RewardsProfileIcon = {
  base: "NoTier",
  ignite: "Ignite",
  launch: "Launch",
  orbit: "Orbit",
  base: "NoTier",
  cruise: "Cruise",
  toTheMoon: "ToTheMoon",
};
